import React from "react";
import logo from "../../assets/logo_full.png";
import { FaCartPlus, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./styles.css";

export default function Navbar() {
  return (
    <>
      <nav className="navbar sticky-top">
        <div className="container">
          <a href="https://www.genesismedics.ng/">
            <img src={logo} />
          </a>
          <div className=" row g-4">
            <div className="col-6">
              <FaUser />
            </div>
            <div className="col-6">
              <FaCartPlus />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
