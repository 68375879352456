import { Link } from 'react-router-dom';
import './style.css';
import { useState } from 'react';
import { LuPackage2 } from 'react-icons/lu';
import { FaCartPlus, FaWhatsapp } from 'react-icons/fa';
import { safeHtml } from '../../utils/sanitizeHtml';
import tests from '../../data/tests.json';
import makeOrder from '../../utils/makeOrder';

export default function ItemList({ items }) {
  let [activeItem, setActiveitem] = useState(items[0]);

  function viewItem(item) {
    setActiveitem(item);
  }
  if (items[0]) {
    return (
      <>
        <div className="mb-5 mt-5">
          <div className="row g-4">
            {items.map((item, index) => {
              if (item.description === '') {
                item.description = `No description provided for '${item.name}', for further enquiries please contact us with at <a href="tel:+2348038608376">+234 803 8608 376</a> or email us at <a href="mailto:info@genesismedics.ng">info@genesismedics.ng</a> or <a href="mailto:genesismedlabs@gmail.com">genesismedlabs@gmail.com</a>.`;
              }
              return (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div
                    className="card"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    onClick={() => viewItem(item)}
                  >
                    <div className="card-body">
                      <h6 className="card-title one-liner">{item.name}</h6>
                      <small className="card-text text-muted one-liner">
                        {item.description}
                      </small>
                      <div className="d-flex justify-content-between pt-2">
                        <p
                          className="card-text one-liner m-0"
                          style={{ fontWeight: '600' }}
                        >
                          {item.price !== 0
                            ? `₦${Number(item.price).toLocaleString()}`
                            : 'Nil'}
                        </p>
                        <small className="text-muted">
                          <LuPackage2 />
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Full Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <section className="modal-body p-5">
                <h1 className="display-6">{activeItem.name}</h1>
                <h4 className="text-muted">
                  {activeItem.price !== 0
                    ? `₦${Number(activeItem.price).toLocaleString()}`
                    : 'Nil'}
                </h4>
                <p
                  className="mt-3"
                  dangerouslySetInnerHTML={{
                    __html: safeHtml(activeItem.description),
                  }}
                ></p>
                {activeItem.tests ? (
                  <div className="mt-5">
                    <h4>Tests Covered</h4>
                    <small>
                      The following list consists of a list of tests covered by
                      this package.
                    </small>
                    <ul>
                      {activeItem.tests.map((test_id, index) => {
                        const test = tests[test_id];
                        return (
                          <li key={index}>
                            {test.name}
                            {test.parameters ? (
                              <ul>
                                {test.parameters.map((parameter, index) => {
                                  return <li key={index}>{parameter}</li>;
                                })}
                              </ul>
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div className="mt-5">
                    {activeItem.parameters.length ? (
                      <>
                        <h4>Tests Parameters</h4>
                        <small>
                          The following list consists of a list of tests
                          parameters to be examined for this test.
                        </small>
                        <ul>
                          {activeItem.parameters.map((parameter, index) => {
                            return <li key={index}>{parameter}</li>;
                          })}
                        </ul>
                      </>
                    ) : null}
                  </div>
                )}
              </section>
              <div className="modal-footer">
                <button type="button" className="btn btn-warning">
                  <FaCartPlus /> Add to Cart
                </button>
                <Link to={makeOrder(activeItem)} className="btn btn-success">
                  <FaWhatsapp /> Place Order
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <section className="mt-5">
        <h4>No match found!</h4>
        <small className="text-muted">
          Sorry no match was found for your search query, we are imporving on
          our search algorithm to better help you find what you are looking for
          with ease. If you cannot find what you are looking for give us a call
          at <a href="tel:+2348038608376">+234 803 8608 376</a> or email us at{' '}
          <a href="mailto:info@genesismedics.ng">info@genesismedics.ng</a> or{' '}
          <a href="mailto:genesismedlabs@gmail.com">genesismedlabs@gmail.com</a>
        </small>
      </section>
    </>
  );
}
