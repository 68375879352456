import React, { useEffect, useState } from "react";
import ItemList from "../../components/ItemList";
import packages from "../../data/packages.json";
import tests from "../../data/tests.json";

export default function Home() {
  const [query, setQuery] = useState("");
  const [list, setList] = useState([...packages, ...Object.values(tests)]);

  function queryList(e) {
    setQuery(e.target.value);
  }

  useEffect(() => {
    if (query === "") {
      setList([...packages, ...Object.values(tests)]);
      return;
    }
    const items = [...packages, ...Object.values(tests)];
    let queryResult = [];
    queryResult.push(
      ...items.filter((item) => {
        if (item.name.toLowerCase().includes(query.toLowerCase())) {
          return true;
        }
        return false;
      })
    );
    queryResult.push(
      ...items.filter((item) => {
        if (item.tests) {
          for (const test_id of item.tests) {
            const test = tests[test_id];
            if (test.name.toLowerCase().includes(query.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      })
    );
    setList(queryResult);
  }, [query]);

  return (
    <>
      <div className="container mt-5 px-lg-5">
        <div className="container-fluid text-center">
          <h1 className="display-6 text-primary">
            Genesis Medical Diagnostics Booking System
          </h1>
          <p className="text-muted">
            Streamline your appointments with ease. Schedule your medical tests
            hassle-free with our user-friendly online platform. Book now for
            convenient and efficient healthcare management.
          </p>
        </div>
        <div className="input mt-4 w-100">
          <input
            type="text"
            className="form-control"
            placeholder="Search Everything"
            value={query}
            onChange={(e) => queryList(e)}
          />
        </div>
        <ItemList items={list} />
      </div>
    </>
  );
}
